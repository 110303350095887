import React, { useMemo, useState } from "react";
import { Button, Form, FormInstance, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { Alarm, AlarmSeverity, FilterType } from "@properate/common";
import { useGetFirestoreCollection } from "@properate/ui";
import { collection, query, where } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { FormValues } from "./AlertRuleDetails";

type Props = {
  form: FormInstance<FormValues>;
};

export function AlarmsQueryBasedSelector({ form }: Props) {
  const t = useTranslations();
  const buildingId = useCurrentBuildingId();
  const [showQueryResults, setShowQueryResults] = useState(false);

  const severity = Form.useWatch(["alarm_rule_selector", "severity"], form);
  const category = Form.useWatch(["alarm_rule_selector", "category"], form);

  const alarms = useGetFirestoreCollection<Alarm>(
    useMemo(() => {
      if (!severity && !category) {
        return null;
      }

      const filters = [where("buildingId", "==", buildingId)];

      if (severity) {
        filters.push(where("severity", "==", severity));
      }

      if (category) {
        filters.push(where("category", "==", category));
      }

      return query(collection(browserFirestore, "alarms"), ...filters);
    }, [severity, category, buildingId]),
  );

  const SEVERITY_OPTIONS: Record<AlarmSeverity, string> = {
    ERROR: t("alarms.severity.ERROR"),
    WARNING: t("alarms.severity.WARNING"),
    INFO: t("alarms.severity.INFO"),
    CRITICAL: t("alarms.severity.CRITICAL"),
  };

  const CATEGORY_OPTIONS: Record<FilterType, string> = {
    energy: t("alarms.category.energy"),
    weather: t("alarms.category.weather"),
    humidity: t("alarms.category.humidity"),
    temperature: t("alarms.category.temperature"),
    motion: t("alarms.category.motion"),
    gass: t("alarms.category.gass"),
    technical: t("alarms.category.technical"),
    ventilation: t("alarms.category.ventilation"),
    heating: t("alarms.category.heating"),
    cooling: t("alarms.category.cooling"),
    sanitary: t("alarms.category.sanitary"),
    building: t("alarms.category.building"),
  };

  return (
    <fieldset className="p-0 border-0 ml-16">
      <Form.Item<FormValues>
        label={t("alert-rule.field-severity")}
        name={["alarm_rule_selector", "severity"]}
      >
        <Select>
          {Object.entries(SEVERITY_OPTIONS).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<FormValues>
        label={t("alert-rule.field-category")}
        name={["alarm_rule_selector", "category"]}
      >
        <Select>
          {Object.entries(CATEGORY_OPTIONS).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className="flex gap-2 items-center">
        {t("alert-rule.found-alarms", {
          count: alarms.data ? alarms.data.length : 0,
        })}
        <Button
          size="small"
          onClick={() => setShowQueryResults(!showQueryResults)}
        >
          {showQueryResults
            ? t("alert-rule.hide-alarms")
            : t("alert-rule.show-alarms")}
        </Button>
      </div>
      {showQueryResults && (
        <Select
          key={[severity, category, buildingId].join("|")}
          mode="multiple"
          className="w-full mt-6"
          loading={alarms.isLoading}
          value={
            alarms.data ? alarms.data.map((alarm) => alarm.snapshotId) : []
          }
        >
          {alarms.data?.map((alarm) => (
            <Select.Option key={alarm.snapshotId} value={alarm.snapshotId}>
              {alarm.name ?? alarm.snapshotId}
            </Select.Option>
          ))}
        </Select>
      )}
    </fieldset>
  );
}
