import { PageHeader } from "@ant-design/pro-layout";
import { Button, Divider, Space, Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { Link, useLoaderData } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { IncidentClientSide } from "@properate/common";
import { useUser } from "@properate/auth";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { CenteredSpinner } from "@properate/ui";
import { CompactContent } from "@/components/CompactContent";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { AlarmTimeseriesGraph } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { useAlarmRule } from "@/pages/alarms/details/hooks/useAlarmRules";
import {
  assignIncidentToUser,
  getIncident,
  sendIncidentToNextPerson,
  updateIncident,
} from "@/eepApi";
import { IncidentInfo } from "./IncidentInfo";
import { IncidentAlarmInfo } from "./IncidentAlarmInfo";
import { IncidentAlarmContextWrapper } from "./IncidentAlarmContextWrapper";
import { IncidentAlarmComponentWrapper } from "./IncidentAlarmComponentWrapper";
import SomethingWrong from "./SomethingWrong";

const queryClient = new QueryClient();

export const Incident = () => {
  const pageData = useLoaderData() as { id: string };
  const t = useTranslations();
  const currentUser = useUser();

  const { data: incidentData, isLoading } = useQuery(
    {
      queryKey: ["incident", pageData.id],
      queryFn: () => {
        return getIncident(pageData.id!);
      },
    },
    queryClient,
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const [status, setStatus] = useState<
    IncidentClientSide["status"] | undefined
  >(incidentData?.status);

  useEffect(() => {
    if (incidentData?.status) {
      setStatus(incidentData.status);
    }
  }, [incidentData?.status]);

  const onAssignToMe = async () => {
    setIsUpdating(true);
    try {
      if (!pageData.id) {
        return;
      }
      const incident = await assignIncidentToUser(pageData.id, {
        email: currentUser.email,
      });
      queryClient.setQueryData<IncidentClientSide>(
        ["incident", pageData.id],
        incident,
      );
    } catch (error) {
      console.error(error);
    }
    setIsUpdating(false);
  };

  const onSendToNext = async () => {
    setIsUpdating(true);
    try {
      if (!pageData.id) {
        return;
      }
      const incident = await sendIncidentToNextPerson(pageData.id);
      queryClient.setQueryData<IncidentClientSide>(
        ["incident", pageData.id],
        incident,
      );
    } catch (error) {
      console.error(error);
    }
    setIsUpdating(false);
  };

  const onSubmit = async () => {
    setIsUpdating(true);
    try {
      if (!pageData.id || !status || !incidentData) {
        return;
      }
      const incident = await updateIncident(pageData.id, {
        ...incidentData,
        status,
      });
      queryClient.setQueryData<IncidentClientSide>(
        ["incident", pageData.id],
        incident,
      );
    } catch (error) {
      console.error(error);
    }
    setIsUpdating(false);
  };

  const onChangeStatus = (status: IncidentClientSide["status"]) => {
    setStatus(status);
  };

  const { alarmRule, error: alarmRuleError } = useAlarmRule(
    incidentData?.alarm_rule_id,
  );

  const title = incidentData?.name;

  const isAssigned = !!incidentData?.assigned?.user;
  const isAssignedToMe =
    incidentData?.assigned?.user.email === currentUser.email;
  const isResolved = incidentData?.status === "resolved";

  const hasNextPerson = true; // TODO: Implement;
  const isNotifyAll = false; // TODO: Implement

  const sendToNextPersonTooltip = useMemo(() => {
    if (isNotifyAll) {
      return t("incident.no-next-person-notify-all-is-on");
    }
    if (hasNextPerson) {
      return "";
    }
    return t("incident.no-next-person");
  }, [hasNextPerson, isNotifyAll, t]);

  useBuildingPageTitle(title || t("incident.title"));

  const assignButtons = !isAssigned
    ? [
        <Button
          key="assign-to-me"
          type="primary"
          onClick={onAssignToMe}
          loading={isUpdating}
          aria-label={t("incident.assign-to-me")}
        >
          {t("incident.assign-to-me")}
        </Button>,
        <Tooltip key="send-to-next" title={sendToNextPersonTooltip}>
          <Button
            key="send-to-next"
            type="primary"
            aria-label={t("incident.send-to-next")}
            onClick={onSendToNext}
            loading={isUpdating}
            disabled={!hasNextPerson || isNotifyAll}
          >
            {t("incident.send-to-next")}
          </Button>
        </Tooltip>,
      ]
    : [];

  if (alarmRuleError) {
    return <SomethingWrong />;
  }

  if (incidentData === undefined || isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <div className="h-full flex flex-col max-h-screen">
      <PageHeader
        title={
          <Space>
            <Link to={`../incidents`}>{t("incidents.title")}</Link>
            <RightOutlined />
            {title}
          </Space>
        }
        extra={[
          ...assignButtons,
          ...(isAssignedToMe && !isResolved
            ? [
                <Button
                  key="save"
                  ghost
                  type="primary"
                  aria-label={t("incident.save")}
                  loading={isUpdating}
                  onClick={onSubmit}
                >
                  {t("incident.save")}
                </Button>,
              ]
            : []),
        ]}
      />
      <CompactContent className="flex">
        <IncidentAlarmContextWrapper alarmRule={alarmRule}>
          <div className="col-span-2 box-border flex flex-col gap-4 flex-1">
            <IncidentAlarmComponentWrapper>
              <AlarmTimeseriesGraph />
            </IncidentAlarmComponentWrapper>
          </div>
          <div className="rounded-lg bg-background-secondary overflow-auto p-4 mb-4 flex-1">
            <div className="flex flex-1 flex-col lg:flex-row">
              <div className="flex-1">
                <IncidentInfo
                  assignButtons={assignButtons}
                  data={incidentData}
                  name={title || ""}
                  onChangeStatus={onChangeStatus}
                  status={status}
                />
              </div>
              <Divider type="vertical" className="mx-5 h-full" />
              <div className="lg:flex-1 flex overflow-y-hidden min-h-[50vh] mt-2 lg:mt-0">
                <IncidentAlarmComponentWrapper>
                  <IncidentAlarmInfo
                    alarmRuleId={incidentData.alarm_rule_id}
                    deviationId={incidentData.alarm_event_id_list[0]}
                  />
                </IncidentAlarmComponentWrapper>
              </div>
            </div>
          </div>
        </IncidentAlarmContextWrapper>
      </CompactContent>
    </div>
  );
};
