import React, { useMemo } from "react";
import {
  Button,
  Card,
  Form,
  FormInstance,
  Select,
  Switch,
  Typography,
} from "antd";
import { useTranslations } from "@properate/translations";
import { useGetFirestoreCollection } from "@properate/ui";
import { AlertGroup } from "@properate/common";
import { browserFirestore } from "@properate/firebase";
import { collection, query, where } from "firebase/firestore";
import { DeleteOutlined } from "@ant-design/icons";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { FormValues } from "./AlertRuleDetails";

type Props = {
  form: FormInstance<FormValues>;
};

export function AlertRuleDetailsFallbackGroup(props: Props) {
  const t = useTranslations();
  const building = useCurrentBuilding();
  const fallbackGroupRule = Form.useWatch("fallback_group_rule", props.form);
  const showTimeout = !fallbackGroupRule?.notify_by_priority.notify_all;
  const hasFallbackGroup =
    fallbackGroupRule &&
    (!!fallbackGroupRule.group_id ||
      Object.values(fallbackGroupRule.notify_by_priority).some((item) => item));

  const onRemove = () => {
    props.form.setFieldValue("fallback_group_rule", {});
  };

  const groups = useGetFirestoreCollection<AlertGroup>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, "alertGroups"),
          where("building_external_id", "==", building.externalId),
        ),
      [building.externalId],
    ),
  );

  return (
    <div className="space-y-1">
      <Typography.Text strong>
        {t("alert-rule.section-fallback-title")}
      </Typography.Text>
      <Card>
        <div className="flex justify-between -mb-6 gap-2">
          <Form.Item<FormValues>
            name={["fallback_group_rule", "group_id"]}
            className="w-[450px]"
          >
            <Select
              placeholder={t("alert-rule.field-group-placeholder")}
              loading={groups.isLoading}
            >
              {groups.data?.map((group) => (
                <Select.Option key={group.snapshotId} value={group.snapshotId}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex-1" />
          <Form.Item<FormValues>
            name={["fallback_group_rule", "notify_by_priority", "notify_all"]}
          >
            <Switch
              checkedChildren={t(
                "alert-rule.field-fallback-notify-all-members-true",
              )}
              unCheckedChildren={t(
                "alert-rule.field-fallback-notify-all-members-false",
              )}
            />
          </Form.Item>
          {showTimeout && (
            <Form.Item
              name={["fallback_group_rule", "notify_by_priority", "timeout"]}
              className="w-28"
            >
              <Select>
                <Select.Option value="10">
                  {t("alert-rule.timeout-options.10-minutes")}
                </Select.Option>
                <Select.Option value="15">
                  {t("alert-rule.timeout-options.15-minutes")}
                </Select.Option>
                <Select.Option value="20">
                  {t("alert-rule.timeout-options.20-minutes")}
                </Select.Option>
                <Select.Option value="25">
                  {t("alert-rule.timeout-options.25-minutes")}
                </Select.Option>
                <Select.Option value="30">
                  {t("alert-rule.timeout-options.30-minutes")}
                </Select.Option>
                <Select.Option value="60">
                  {t("alert-rule.timeout-options.1-hour")}
                </Select.Option>
                <Select.Option value="120">
                  {t("alert-rule.timeout-options.2-hours")}
                </Select.Option>
              </Select>
            </Form.Item>
          )}
          {hasFallbackGroup && (
            <Button type="text" icon={<DeleteOutlined />} onClick={onRemove} />
          )}
        </div>
      </Card>
    </div>
  );
}
