import useMutation from "swr/mutation";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { AlertRule } from "@properate/common";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";

export function useUpdateAlertRule() {
  const t = useTranslations();

  return useMutation(
    ["alerts", "rules", "update"],
    async (_key, params: { arg: { rule: AlertRule; snapshotId: string } }) => {
      await updateDoc(
        doc(collection(browserFirestore, "alertRules"), params.arg.snapshotId),
        params.arg.rule,
      );
    },
    {
      onSuccess() {
        message.success(t("alert-rule.status-update-success"));
      },
      onError(error) {
        message.error(t("alert-rule.status-update-error"));
        console.error(error);
      },
    },
  );
}

export function useCreateAlertRule() {
  const t = useTranslations();
  const building = useCurrentBuilding();
  const navigate = useNavigate();

  return useMutation(
    ["alerts", "rules", "create"],
    async (_key, params: { arg: Omit<AlertRule, "building_external_id"> }) => {
      const docRef = await addDoc(collection(browserFirestore, "alertRules"), {
        ...params.arg,
        building_external_id: building.externalId,
      });

      return { snapshotId: docRef.id, ...params.arg };
    },
    {
      onSuccess(config) {
        message.success(t("alert-rule.status-create-success"));
        navigate(
          `/asset/${building.id}/alertConfiguration/${config.snapshotId}`,
        );
      },
      onError(error) {
        message.error(t("alert-rule.status-create-error"));
        console.error(error);
      },
    },
  );
}

export function useDeleteAlertRule() {
  const t = useTranslations();
  const buildingId = useCurrentBuildingId();
  const navigate = useNavigate();

  return useMutation(
    ["alerts", "rules", "delete"],
    async (_key, params: { arg: { snapshotId: string } }) => {
      await deleteDoc(
        doc(collection(browserFirestore, "alertRules"), params.arg.snapshotId),
      );
    },
    {
      onSuccess() {
        message.success(t("alert-rule.status-delete-success"));
        navigate(`/asset/${buildingId}/alertConfiguration`);
      },
      onError(error) {
        message.error(t("alert-rule.status-delete-error"));
        console.error(error);
      },
    },
  );
}

export async function fetchAlertRule(id: string) {
  const docRef = await getDoc(
    doc(collection(browserFirestore, "alertRules"), id),
  );

  return docRef.data() as AlertRule;
}
