import { Select, SelectProps } from "antd";
import { sortString } from "@properate/ui";
import { captureMessage } from "@sentry/react";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";
import { FloorPlan } from "./types";

export function useFloorPlanFloorOptions() {
  const assets = useFloorPlanAssets();
  const buildingId = useCurrentBuildingId();

  const sortedFloors = assets.data
    ? assets.data.floors.sort((a, b) =>
        sortString(a.description, b.description),
      )
    : [];

  const options = sortedFloors.reduce(
    (floors, floor) => {
      if (floor.description && floor.externalId) {
        floors[floor.description] = floors[floor.description]
          ? `${floors[floor.description]},${floor.externalId}`
          : floor.externalId;
      }

      return floors;
    },
    {} as Record<string, string>,
  );

  return {
    options,
    getFloorName(record: FloorPlan) {
      if (!record.floor || !assets.data) {
        return "";
      }

      for (const [floor, externalIds] of Object.entries(options)) {
        if (externalIds.includes(record.floor)) {
          return floor;
        }
      }

      captureMessage("Sub-building is not available for building.", {
        extra: {
          floor: record.floor,
          buildingId,
          availableFloors: options,
        },
      });

      return "";
    },
  };
}

export function FloorPlanFloorSelect(props: SelectProps<string>) {
  const floors = useFloorPlanFloorOptions();

  return (
    <Select {...props}>
      {props.children}
      {Object.entries(floors).map(([floor, externalIds]) => (
        <Select.Option key={floor} value={externalIds}>
          {floor}
        </Select.Option>
      ))}
    </Select>
  );
}
