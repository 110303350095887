import { PageHeader } from "@ant-design/pro-layout";
import { type ColumnsType } from "antd/lib/table";
import AutoSizer from "react-virtualized-auto-sizer";
import { Button, Input, Popconfirm } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { AlertRule } from "@properate/common";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { CompactContent } from "@/components/CompactContent";
import Highlight from "@/components/Highlight";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useDeleteAlertRule, useUpdateAlertRule } from "../ruleDetails/utils";
import { useAlertRulesData } from "./utils";
import { BellIcon } from "./icons/BellIcon";
import { BellOffIcon } from "./icons/BellOffIcon";

type LoaderData = {
  search: string;
};

export function AlertRules() {
  const t = useTranslations();
  const buildingId = useCurrentBuildingId();
  const pageData = useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const { configs, onLoadMore, isLoading } = useAlertRulesData();
  const deleteAlertRule = useDeleteAlertRule();
  const updateAlertRule = useUpdateAlertRule();

  const onDelete = (id: string) => {
    deleteAlertRule.trigger({
      snapshotId: id,
    });
  };

  const onEdit = (id: string) => {
    navigate(`/asset/${buildingId}/alertConfiguration/${id}`);
  };

  const onToggleMute = (id: string, rule: AlertRule) => {
    updateAlertRule.trigger({
      snapshotId: id,
      rule: {
        ...rule,
        muted: !rule.muted,
      },
    });
  };

  const onSort = (_prop: string, _order: "ascend" | "descend") => {
    //
  };

  const onSearch = (_term: string) => {
    //
  };

  const columns: ColumnsType<object> = [
    {
      title: t("alert-rule.field-name"),
      key: "name",
      sorter: true,
      defaultSortOrder: "ascend",
      render: ({ name, highlight }) =>
        highlight?.name ? (
          <Highlight dangerouslySetInnerHTML={{ __html: highlight?.name }} />
        ) : (
          name
        ),
    },
    {
      title: t("alert-rule.field-severity"),
      key: "severity",
      sorter: true,
    },
    {
      title: t("alert-rule.field-category"),
      key: "category",
      sorter: true,
    },
    {
      title: t("alert-rule.field-mute"),
      key: "muted",
      sorter: true,
      render: (rule) =>
        rule.muted ? (
          <Button
            icon={<BellOffIcon />}
            onClick={() => onToggleMute(rule.snapshotId, rule)}
          />
        ) : (
          <Button
            icon={<BellIcon />}
            onClick={() => onToggleMute(rule.snapshotId, rule)}
          />
        ),
    },
    {
      title: t("alert-rule.column-currently-responsible-group"),
      key: "currentResponsibleGroup",
      sorter: true,
    },
    {
      key: "snapshotId",
      width: 100,
      render: (row) => (
        <div className="flex gap-2 items-center justify-center">
          <Popconfirm
            title={t("alert-groups.table.delete")}
            onConfirm={() => onDelete(row.snapshotId)}
          >
            <Button
              aria-label={t("alert-groups.table.delete")}
              icon={<DeleteOutlined />}
              danger
            />
          </Popconfirm>
          <Button
            onClick={() => onEdit(row.snapshotId)}
            icon={<EditOutlined />}
            aria-label={t("alert-groups.table.edit")}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="h-full w-full flex flex-col">
      <PageHeader
        title={t("alert-rule.title")}
        extra={[
          // <Input.Search
          //   key="search"
          //   defaultValue={pageData.search}
          //   allowClear
          //   style={{ width: 300 }}
          //   onChange={(event) => onSearch(event.target.value)}
          //   aria-label={t("ui.search")}
          //   placeholder={t("ui.search")}
          // />,
          // <Button key="filter" icon={<FilterOutlined />} />,
          <Link key="new" to={`/asset/${buildingId}/alertConfiguration/create`}>
            <Button>{t("ui.create")}</Button>
          </Link>,
        ]}
      />
      <CompactContent className="h-full flex-1 mt-4">
        <div className="h-full flex">
          <AutoSizer className="flex-1">
            {({ height }) => (
              <TableInfiniteScroll
                columns={columns}
                dataSource={configs}
                height={height - 64}
                onReachedEndOfPage={onLoadMore}
                rowKey="id"
                loading={isLoading}
                onChange={(pagination, filter, sorter) => {
                  const sort = Array.isArray(sorter) ? sorter[0] : sorter;

                  if (sort?.columnKey && sort?.order) {
                    onSort(sort.columnKey as string, sort.order);
                  }
                }}
              />
            )}
          </AutoSizer>
        </div>
      </CompactContent>
    </div>
  );
}

AlertRules.loader = function loader(args: LoaderFunctionArgs): LoaderData {
  const urlParams = new URL(args.request.url).searchParams;
  const search = urlParams.get("search") ?? "";

  return {
    search,
  };
};
