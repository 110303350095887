import { SchemaType } from "@properate/common";
import { useCallback } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { SchemaKpis } from "@/pages/common/SchemaKpis/SchemaKpis";
import { FloorPlan } from "@/pages/floorPlan/v2/types";
import { useFloorPlan } from "./FloorPlanContext";

export function FloorPlanHeaderKPIs() {
  const floorPlan = useFloorPlan();

  const sensors = floorPlan.pins.reduce(
    (sensors, pin) => {
      sensors[pin.timeseriesId] = {
        timeseriesInfo: [
          {
            id: pin.timeseriesId,
          },
        ],
      };

      return sensors;
    },
    {} as SchemaType["sensors"],
  );

  const schema: Pick<SchemaType, "selectedSensors" | "sensors"> = {
    selectedSensors: floorPlan.selectedSensors,
    sensors,
  };

  const update = useCallback(
    async (updatedSchema: Partial<FloorPlan>) => {
      await setDoc(
        doc(collection(browserFirestore, "floor-plans"), floorPlan.snapshotId),
        updatedSchema,
        { merge: true },
      );
      return { ...schema, ...updatedSchema } as FloorPlan;
    },
    [floorPlan.snapshotId, schema],
  );

  return (
    <SchemaKpis
      schema={schema}
      update={update}
      snapshotId={floorPlan.snapshotId}
      type="floor-plans"
    />
  );
}
