import { browserFirestore } from "@properate/firebase";
import { useGetFirestoreCollection } from "@properate/ui";
import { collection, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { AlertRule } from "@properate/common";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";

export const useAlertRulesData = () => {
  const building = useCurrentBuilding();

  const rules = useGetFirestoreCollection<AlertRule>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, "alertRules"),
          where("building_external_id", "==", building.externalId),
        ),
      [building],
    ),
  );

  return {
    configs: rules.data,
    onLoadMore: () => undefined,
    isLoading: rules.isLoading,
  };
};
