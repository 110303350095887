import { ColumnsType } from "antd/lib/table/interface";
import {
  getSimplifiedPriority,
  getUserNameWithEmail,
  IncidentClientSide,
  keysForAlarmPriority,
} from "@properate/common";
import dayjs from "@properate/dayjs";
import { useTranslations, MessageKey } from "@properate/translations";
import Highlight from "@/components/Highlight";

export const useIncidentColumns = () => {
  const t = useTranslations();

  return [
    {
      title: t("incidents.table.name"),
      key: "name",
      sorter: true,
      defaultSortOrder: "ascend",
      render: ({ highlight, name }) =>
        highlight?.name ? (
          <Highlight dangerouslySetInnerHTML={{ __html: highlight?.name }} />
        ) : (
          name
        ),
    },
    {
      title: t("incidents.table.active"),
      dataIndex: "has_active_event",
      key: "active",
      width: 100,
      align: "center",
      render: (active) =>
        active ? (
          <div className="w-3 h-3 bg-red-500 rounded-full m-auto" />
        ) : undefined,
    },
    {
      title: t("incidents.table.last-comment"),
      dataIndex: "last_comment",
      key: "last-comment",
    },
    {
      title: t("incidents.table.timeserie"),
      dataIndex: "timeseries_name",
      key: "timeseries",
    },
    {
      title: t("incidents.table.severity"),
      dataIndex: "priority",
      key: "priority",
      render: (severity) =>
        t(keysForAlarmPriority[getSimplifiedPriority(severity)] as MessageKey),
    },
    {
      title: t("incidents.table.responsible"),
      key: "responsible",
      render: ({ assigned, acknowledged }: IncidentClientSide) => {
        if (assigned?.user) {
          return (
            <div className="font-bold text-blue-500">
              {getUserNameWithEmail(assigned.user)}
            </div>
          );
        }
        return acknowledged?.user
          .map((user) => getUserNameWithEmail(user))
          .join(", ");
      },
    },
    {
      title: t("incidents.table.reported-at"),
      dataIndex: "start",
      key: "start",
      sorter: true,
      render: (start) => dayjs(start).format("DD/MM YYYY HH:mm"),
    },
    {
      title: t("incidents.table.resolved-at"),
      dataIndex: "end",
      key: "resolved-at",
      render: (end) => (end ? dayjs(end).format("DD/MM YYYY HH:mm") : ""),
    },
    {
      title: t("incidents.table.status"),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div>{t(`incident.statuses.${status}` as MessageKey)}</div>
      ),
    },
  ] as ColumnsType<IncidentClientSide>;
};
