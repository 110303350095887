import { useState } from "react";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";

export const IncidentTimer = ({ sendToNextAt }: { sendToNextAt: number }) => {
  const t = useTranslations();
  const [time, setTime] = useState<number>();

  setInterval(() => {
    const sendToNextIn = sendToNextAt - Date.now();
    setTime(sendToNextIn > 0 ? sendToNextIn : 0);
  });

  return time ? (
    <div className="flex gap-2 items-center">
      {t("incident.will-be-send-to-next-in")}
      <div className="text-red-700 font-bold text-lg">
        {dayjs(time).format("mm:ss")}
      </div>
    </div>
  ) : null;
};
