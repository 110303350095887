import { browserFirestore } from "@properate/firebase";
import { useGetFirestoreCollection } from "@properate/ui";
import { Form, Select } from "antd";
import { collection, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { Alarm } from "@properate/common";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { FormValues } from "./AlertRuleDetails";

export function AlarmsSelector() {
  const buildingId = useCurrentBuildingId();

  const options = useGetFirestoreCollection<Alarm>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, "alarms"),
          where("buildingId", "==", buildingId),
        ),
      [buildingId],
    ),
  );

  return (
    <Form.Item<FormValues> name={["alarm_rule_ids"]}>
      <Select
        mode="multiple"
        allowClear
        className="w-full ml-16"
        loading={options.isLoading}
      >
        {options.data?.map((alarm) => (
          <Select.Option key={alarm.snapshotId} value={alarm.snapshotId}>
            {alarm.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
