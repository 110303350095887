import { browserFirestore } from "@properate/firebase";
import { sortString, useGetFirestoreCollection } from "@properate/ui";
import { collection, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { FloorPlan } from "../types";
import { useFloorPlan } from "../FloorPlanContext";

export function useFloorPlans() {
  const buildingId = useCurrentBuildingId();

  const ref = useMemo(
    () =>
      query(
        collection(browserFirestore, "floor-plans"),
        where("buildingId", "==", buildingId),
      ),
    [buildingId],
  );

  const {
    data = [],
    isLoading,
    error,
  } = useGetFirestoreCollection<FloorPlan>(ref);

  return {
    data: data
      .filter((item) => !item.softDeleted)
      .sort((a, b) => sortString(a.snapshotId, b.snapshotId)),
    isLoading,
    error,
  };
}

export function useFloorPlanNavigation() {
  const floorPlans = useFloorPlans();
  const floorPlan = useFloorPlan();

  const currentIndex = floorPlans.data.findIndex(
    (item) => item.snapshotId === floorPlan.snapshotId,
  );

  const lastIndex = floorPlans.data.length - 1;

  const nextIndex = currentIndex < lastIndex ? currentIndex + 1 : null;
  const previousIndex = currentIndex > 0 ? currentIndex - 1 : null;

  return {
    next: nextIndex !== null ? floorPlans.data[nextIndex] : null,
    previous: previousIndex !== null ? floorPlans.data[previousIndex] : null,
  };
}
