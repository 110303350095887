import { useUser } from "@properate/auth";
import { useClickAway } from "ahooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslations } from "@properate/translations";
import styled, { css } from "styled-components";
import { Timeseries } from "@cognite/sdk";
import { Button, Form, Select, Spin, Space, App, InputNumber } from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  SettingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import {
  getSystemCodeFromExternalId,
  WithRequired,
  DERIVED_UNITS,
  getValueForMeasurement,
} from "@properate/common";
import { CustomDndProvider } from "@properate/ui";
import useSWR from "swr";
import { getStateDescription, parseError } from "@/pages/common/utils";
import { SetPointStatusJs } from "@/context/api/apiBatch";
import { updateSetPoint } from "@/eepApi";
import { useProperateApiClient } from "@/context/ProperateApiContext";
import { ACCENT2, ERROR, NEUTRAL10, PRIMARY } from "@/utils/ProperateColors";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import warning from "./icons/warning.svg";
import error from "./icons/error.svg";
import ok from "./icons/ok.svg";
import status from "./icons/status.svg";
import {
  formatMeasurementForSchema,
  formatSetPointValueForSchema,
  formatUnit,
  getAlarmClass,
  isValid,
} from "./utils";
import { ItemTypes } from "./ItemTypes";

type Props = {
  x: number;
  y: number;
  timeseriesInfo: any[];
  position: "top" | "left" | "right" | "bottom" | "center";
  openSettings: Function;
  openGraph: Function;
  openRoom: Function;
  openSetPointSettings: Function;
  mouseEnter?: Function;
  mouseLeave?: Function;
  highlight: boolean;
  view: boolean;
  id: string;
  type: string;
  draggable?: boolean;
  editable?: boolean;
  notFoundTimeseriesList: { id: number; key: string }[];
  setPoint?: boolean;
};

const ShowTooltip = styled.div`
  text-align: center;
  margin: 2px;
  display: flex;
  flex-direction: column;
`;

// @ts-ignore
const TooltipContainer = styled.div`
  text-align: center;
  max-width: 300px;
  text-overflow: ellipsis;
  max-height: 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  ${ShowTooltip}:hover &, ${ShowTooltip}.all & {
    transition-delay: 0s;
    max-height: 300px;
    opacity: 1;
    margin: 4px 0 13px 0;
  }

  padding: 5px;
  border-radius: 5px;

  html.light & {
    background: ${NEUTRAL10};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }

  html.dark & {
    background: #293647;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  }

  .move ${ShowTooltip}:hover & {
    max-height: 0;
    margin: 0;
    opacity: 0;
  }
`;
const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  &.highlight,
  &:hover {
    z-index: 1000;
  }

  &.highlight ${TooltipContainer} {
    max-height: 300px;
    opacity: 1;
    margin: 4px 0 13px 0;
  }

  &.right {
    align-items: flex-start;
    transform: translate(0, -100%);

    ${ShowTooltip} {
      text-align: left;
    }

    ${TooltipContainer} {
      transform: translate(-25%, 0);
      //text-align: left;
      //padding-left: 7px;
    }
  }

  &.left {
    align-items: flex-end;
    transform: translate(-100%, -100%);

    ${ShowTooltip} {
      text-align: right;
    }

    ${TooltipContainer} {
      transform: translate(25%, 0);
    }
  }

  &.top {
    transform: translate(-50%, -100%);
  }

  &.bottom {
    ${TooltipContainer} {
      order: 2;
    }

    transform: translate(-50%, 0);
  }

  &.center {
    transform: translate(-50%, -100%);
  }
`;
const Status = styled.div<{ $edit?: boolean; $setPoint?: boolean }>`
  background: ${(props) => props.theme.background};
  pointer-events: auto;

  html.dark & {
    background: #293647;
  }

  display: inline-block;
  text-align: end;
  padding: 4px 4px;
  border: 1px solid #293647;
  box-sizing: border-box;
  border-radius: 24px;
  font-size: 13px;
  line-height: 22px;
  min-width: 32px;
  height: 32px;
  position: relative;
  transition: filter 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  html.light & {
    border: 1px solid #182334;
  }

  ${(props) =>
    props.$setPoint &&
    css`
      border: 1px solid ${ACCENT2} !important;
    `}

  ${(props) =>
    props.$edit &&
    css`
      padding: 1px 4px;
    `}

  .icon::before {
    position: relative;
    top: -1px;
  }

  ${ShowTooltip}.minimal & .icon::before {
    content: url(${ok});
  }

  &.ok .icon::before {
    content: url(${ok}) !important;
  }

  &.warning .icon::before {
    content: url(${warning}) !important;
  }

  &.status .icon::before {
    content: url(${status}) !important;
  }

  &.error .icon::before {
    content: url(${error}) !important;
  }

  ${StatusContainer}.highlight &, &:hover {
    cursor: pointer;
  }

  html.light ${StatusContainer}.highlight &,
  html.light &:hover {
    background: ${NEUTRAL10};
  }

  .move &:hover {
    cursor: move !important;
  }
`;
const Measurement = styled.span`
  vertical-align: top;
  margin: 0 12px 0 12px;
  display: inline-block;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  white-space: nowrap;
  text-overflow: clip;

  ${ShowTooltip}.minimal & {
    max-width: 0;
    margin: 0;
    opacity: 0;
  }

  ${ShowTooltip}.all ${Status}.warning &, ${ShowTooltip}.all ${Status}.error &, ${ShowTooltip}.value ${Status}.warning &, ${ShowTooltip}.value ${Status}.error &, ${ShowTooltip}.value ${Status}.status &, ${StatusContainer}.highlight ${ShowTooltip}.minimal &, ${ShowTooltip}.minimal:hover & {
    max-width: 120px;
    margin: 0 12px 0 4px;
    opacity: 1;
  }

  ${StatusContainer}.right &:last-child {
    // margin-left: 0; I think this is a bug, will test a bit before removing it completely
  }

  ${StatusContainer}.right ${ShowTooltip}.all &:last-child, ${StatusContainer}.right ${ShowTooltip}.minimal:hover &:last-child {
    margin-left: 8px;
  }

  ${StatusContainer}.left &:first-child {
    margin-left: 0;
  }

  ${StatusContainer}.top &:last-child {
    margin-bottom: 0;
  }

  ${StatusContainer}.bottom &:first-child {
    margin-top: 0;
  }
`;

const getSetPointErrorClass = (setPointStatus?: SetPointStatusJs) => {
  if (!setPointStatus) {
    return "";
  }
  if (setPointStatus.outOfService) {
    return "error";
  }
  if (setPointStatus.fault) {
    return "warning";
  }
  return "";
};

const getSetPointErrorDescription = (setPointStatus?: SetPointStatusJs) => {
  if (setPointStatus?.outOfService) {
    return "Ute av drift";
  }
  if (setPointStatus?.fault) {
    return setPointStatus?.fault;
  }
  return "";
};

const Alarm = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const getSetPointValue = (
  setPointValue: number,
  originalUnit: string,
  unit?: string,
  stateDescription?: Record<number, string>,
) => {
  if (stateDescription) {
    return stateDescription[setPointValue];
  }
  return formatSetPointValueForSchema({
    unit,
    value: convertValue(originalUnit, unit, setPointValue),
  });
};

const getValue = (
  value?: number,
  unit?: string,
  stateDescription?: Record<number, string>,
) => {
  if (value === undefined) {
    return formatMeasurementForSchema({
      unit,
      value,
    });
  }
  if (stateDescription) {
    return stateDescription[value];
  }

  return formatMeasurementForSchema({
    unit,
    value,
  });
};

const getAlarm = (
  value: number,
  alarmType: "warning" | "error" | "status",
  unit: string,
  max?: number,
  min?: number,
  maxView = `Over ${getValueForMeasurement({ value: max, unit })}${formatUnit(
    unit || "",
  )}`,
  minView = `Under ${getValueForMeasurement({ value: min, unit })}${formatUnit(
    unit || "",
  )}`,
) => {
  const aboveMax = max !== undefined && value > max;
  const belowMin = min !== undefined && value < min;
  if (aboveMax) {
    return (
      <Alarm
        style={
          alarmType === "error"
            ? { color: "#FF5129" }
            : alarmType === "warning"
            ? { color: "#FFD80B" }
            : {}
        }
      >
        {maxView}
      </Alarm>
    );
  } else if (belowMin) {
    return (
      <Alarm
        style={
          alarmType === "error"
            ? { color: "#FF5129" }
            : alarmType === "warning"
            ? { color: "#FFD80B" }
            : {}
        }
      >
        {minView}
      </Alarm>
    );
  }
  return undefined;
};

const convertValue = (fromUnit = "", toUnit = "", value?: number) => {
  const convert =
    fromUnit !== toUnit ? DERIVED_UNITS[fromUnit][toUnit].to : (x: number) => x;
  return value !== undefined ? convert(value) : undefined;
};

const revertValue = (fromUnit = "", toUnit = "", value?: number) => {
  const revert =
    fromUnit !== toUnit
      ? DERIVED_UNITS[fromUnit][toUnit].from
      : (x: number) => x;
  return value !== undefined ? revert(value) : undefined;
};

export const Overlay = ({
  openGraph,
  openRoom,
  openSettings,
  openSetPointSettings,
  x,
  y,
  timeseriesInfo,
  position,
  mouseEnter,
  mouseLeave,
  highlight,
  id,
  type,
  draggable,
  editable,
  notFoundTimeseriesList,
  setPoint,
}: Props) => {
  const properateCogniteClient = useProperateCogniteClient();
  const properateApíClient = useProperateApiClient();
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);
  const t = useTranslations();

  const [form] = Form.useForm();
  const { message } = App.useApp();
  const user = useUser();
  const [map, setMap] = useState<Record<number, any>>();
  const [valueMap, setValueMap] = useState<Record<number, any>>();
  const [edit, setEdit] = useState(false);
  const [updating, setUpdating] = useState(false);
  const editRef = useRef<HTMLDivElement>(null);
  useClickAway(() => {
    setEdit(false);
  }, editRef);
  const [{ isDragging }, drag] = CustomDndProvider.useDrag(
    () => ({
      type: ItemTypes.OVERLAY,
      item: { x, y, position, id, timeseriesInfo, type },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: draggable,
    }),
    [x, y, draggable, id, timeseriesInfo, position, type],
  );

  useEffect(() => {
    let mounted = true;
    const get = async () => {
      const timeseries =
        timeseriesInfo.length > 0
          ? await properateCogniteClient.getTimeseriesMany(
              timeseriesInfo.map((ts) => ts.id),
            )
          : [];

      const timeseriesIdToTimeseriesInfo = Promise.resolve(
        timeseriesInfo.reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {},
        ),
      );

      const timeseriesIdToTimeseriesInfoWithRoom = await timeseries
        .filter(
          (ts): ts is WithRequired<Timeseries, "externalId"> =>
            Boolean(ts) && typeof ts.externalId === "string",
        )
        .reduce(async (tsIdToTsInfo, ts) => {
          const prevLookup = await tsIdToTsInfo;
          const roomInfo = getSystemCodeFromExternalId(
            ts.externalId,
          ).startsWith("200")
            ? await properateCogniteClient.getRoomInfo(ts.assetId!)
            : undefined;

          return {
            ...prevLookup,
            [ts.id]: {
              ...prevLookup[ts.id],
              name: roomInfo ? roomInfo.name : ts.name,
              description: ts.description,
              unit:
                (overrideUnits && overrideUnits[ts?.externalId]?.unit) ||
                prevLookup[ts.id]?.unit ||
                ts.unit,
              originalUnit: ts.unit,
              externalId: ts.externalId,
              stateDescription: getStateDescription(
                ts.metadata?.state_description,
              ),
              maxValue: ts.metadata?.max_value || Number.MAX_SAFE_INTEGER,
              minValue: ts.metadata?.min_value || Number.MIN_SAFE_INTEGER,
              roomId: roomInfo?.id,
            },
          };
        }, timeseriesIdToTimeseriesInfo);

      if (mounted) {
        setMap(timeseriesIdToTimeseriesInfoWithRoom);
      }
    };
    get();
    return () => {
      mounted = false;
    };
  }, [timeseriesInfo, properateCogniteClient, overrideUnits]);

  const setPointExternalId = useMemo(() => {
    return id.startsWith("set-point") && map && Object.keys(map).length > 0
      ? Object.values(map)[0].externalId
      : undefined;
  }, [map]);

  const { data: setPointStatus, mutate } = useSWR(
    `setpoint-status-${setPointExternalId}`,
    async () => properateApíClient.getSetPointStatus(setPointExternalId),
    { refreshInterval: edit ? 0 : 30000 },
  );

  useEffect(() => {
    if (setPointStatus && !edit) {
      form.setFieldValue("value", setPointStatus.value);
    }
  }, [setPointStatus, form]);

  useEffect(() => {
    if (
      setPointStatus &&
      map &&
      Object.values(map).length > 0 &&
      Object.values(map)[0].unit !== Object.values(map)[0].originalUnit
    ) {
      form.setFieldValue(
        "value",
        convertValue(
          Object.values(map)[0].originalUnit,
          Object.values(map)[0].unit,
          setPointStatus.value,
        ),
      );
    }
  }, [setPointStatus, map, form]);

  useEffect(() => {
    let mounted = true;

    if (map && timeseriesInfo) {
      let timeoutId: any = 0;
      const update = async () => {
        try {
          const values =
            timeseriesInfo.length > 0
              ? await properateCogniteClient.getLatestValueMany(
                  timeseriesInfo.map((ts) => ts.id),
                )
              : [];

          if (
            !values.reduce(
              (prev, current) => prev && map[current.id] !== undefined,
              true,
            )
          ) {
            if (mounted) {
              timeoutId = setTimeout(update, 5000);
            }
            return;
          }

          const v = values.reduce(
            (prev, current) => {
              const ts = map[current.id];
              const convertUnit = ts && ts.unit && ts.unit !== current.unit;
              const convert = convertUnit
                ? DERIVED_UNITS[current.unit ?? ""][ts.unit].to
                : (x: number) => x;
              const point = convertUnit
                ? {
                    ...current.datapoints[0],
                    value: convert(current.datapoints[0].value as number),
                  }
                : current.datapoints[0];
              return {
                ...prev,
                [current.id]: {
                  ...ts,
                  ...point,
                  min: convertUnit ? convert(ts.min) : ts.min,
                  max: convertUnit ? convert(ts.max) : ts.max,
                },
              };
            },
            {} as Record<number, any>,
          );
          if (mounted) {
            setValueMap(v);
            if (!edit && setPointStatus) {
              form.setFieldValue(
                "value",
                convertValue(
                  v[Object.values(v)[0].id].originalUnit,
                  v[Object.values(v)[0].id].unit,
                  setPointStatus?.value,
                ),
              );
            }
            timeoutId = setTimeout(update, 5000);
          }
        } catch (error: any) {
          console.warn(`Update failed - aborting: ${error.message} `);
        }
      };

      update();

      return () => {
        clearTimeout(timeoutId);
        mounted = false;
      };
    }
  }, [timeseriesInfo, properateCogniteClient, map]);

  if (isDragging) {
    return null;
  }

  function isNotFound(id: number) {
    if (notFoundTimeseriesList.length === 0) {
      return false;
    }
    return notFoundTimeseriesList.map((ts) => ts.id).includes(id);
  }

  function showMissingTimeseriesOrSpinner(id: number, roomId: number) {
    if (notFoundTimeseriesList.length === 0) {
      return null;
    }
    if (isNotFound(id)) {
      return (
        <span
          style={{ margin: "0 10px", color: "red" }}
          onClick={() => openGraph(id)}
        >
          - -
        </span>
      );
    }
    return <Spin indicator={<LoadingOutlined />} />;
  }

  function showOnMouseHoverMissingTimeserie(id: number) {
    if (notFoundTimeseriesList.length === 0) {
      return null;
    }
    const ts = notFoundTimeseriesList.find((ts) => ts.id === id);
    if (ts) {
      const id = ts.id;
      const key = ts.key;
      return (
        <div>
          <span>{t("floor-plan.timeseries-not-found", { id })} </span> <br />
          {t("floor-plan.label-key", { key })}
        </div>
      );
    }
    return null;
  }

  let editTimeout: ReturnType<typeof setTimeout>;
  const handleBlurEdit = () => {
    clearTimeout(editTimeout);
    editTimeout = setTimeout(() => {
      setEdit(false);
    }, 200);
  };

  const handleFinish = async (values: Record<string, number>) => {
    const externalId = map && Object.values(map)[0].externalId;
    const fromUnit = map && Object.values(map)[0].originalUnit;
    const toUnit = map && Object.values(map)[0].unit;
    const value = revertValue(fromUnit, toUnit, Number(values.value))!;
    if (externalId && setPointStatus) {
      try {
        setUpdating(true);
        await updateSetPoint({
          external_id: externalId,
          value,
          audit_source: "web/technicalSchema",
          priority: setPointStatus.priority,
        });
        await mutate({ ...setPointStatus, value });
      } catch (error) {
        console.error(error);
        const errorMessage = parseError(error);
        message.error({
          type: "error",
          content: t("floor-plan.error-updating-set-point", { errorMessage }),
          duration: 7,
        });
      } finally {
        handleBlurEdit();
        setUpdating(false);
      }
    }
  };

  return (
    <StatusContainer
      style={{ position: "absolute", top: y, left: x }}
      className={position + (highlight ? " highlight" : "")}
      onMouseEnter={() => mouseEnter && mouseEnter()}
      onMouseLeave={() => mouseLeave && mouseLeave()}
      ref={drag}
      key={id}
    >
      {map &&
        valueMap &&
        Object.values(map).map((v) => (
          <ShowTooltip key={v.id} className={setPoint && edit ? "all" : v.view}>
            <TooltipContainer>
              {!setPoint &&
                valueMap[v.id] &&
                typeof valueMap[v.id].value === "number" &&
                !isValid(valueMap[v.id].timestamp) && (
                  <Alarm style={{ color: "#ffd80b" }}>
                    {t("floor-plan.last-data-point-came-for", {
                      value: dayjs(valueMap[v.id].timestamp).fromNow(),
                    })}
                  </Alarm>
                )}
              {!setPoint &&
              valueMap[v.id] &&
              typeof valueMap[v.id].value === "number" &&
              valueMap[v.id]
                ? getAlarm(
                    valueMap[v.id].value,
                    v.alarmType || "warning",
                    valueMap[v.id].unit,
                    valueMap[v.id].max,
                    valueMap[v.id].min,
                    valueMap[v.id].maxView,
                    valueMap[v.id].minView,
                  )
                : null}
              {setPoint && getSetPointErrorDescription(setPointStatus) ? (
                <Alarm style={{ color: "#ffd80b" }}>
                  {getSetPointErrorDescription(setPointStatus)}
                </Alarm>
              ) : undefined}
              {isNotFound(v.id) ? (
                showOnMouseHoverMissingTimeserie(v.id)
              ) : (
                <>
                  <span style={{ whiteSpace: "nowrap" }}>{v.description}</span>
                  <br />
                  {v.name}
                </>
              )}
            </TooltipContainer>
            <div>
              <Status
                $edit={edit}
                $setPoint={setPoint}
                onClick={(event) => {
                  event.stopPropagation();
                  if (setPoint) {
                    if (user.isViewer) {
                      return;
                    }
                    if (setPointStatus) {
                      setEdit(true);
                    } else {
                      openSettings(1);
                      handleBlurEdit();
                    }
                    return;
                  }
                  if (valueMap[v.id]) {
                    if (typeof valueMap[v.id].value === "number") {
                      openGraph(v.id);
                    } else {
                      if (user.isViewer) {
                        return;
                      }
                      openSettings(1);
                    }
                  }
                }}
                className={
                  setPoint
                    ? getSetPointErrorClass(setPointStatus)
                    : valueMap[v.id]
                    ? getAlarmClass(
                        valueMap[v.id]?.value,
                        v.alarmType || "warning",
                        valueMap[v.id].max,
                        valueMap[v.id].min,
                      )
                    : undefined
                }
              >
                <span
                  className={
                    valueMap[v.id] &&
                    (typeof v.max === "number" || typeof v.min === "number"
                      ? "icon"
                      : "")
                  }
                />
                {!setPoint && valueMap[v.id] && (
                  <>
                    <Measurement
                      style={
                        isValid(valueMap[v.id].timestamp)
                          ? {}
                          : { color: "#ffd80b" }
                      }
                    >
                      <Space>
                        {getValue(
                          valueMap[v.id].value,
                          valueMap[v.id].unit,
                          v.stateDescription,
                        )}
                        {editable && (
                          <EditOutlined
                            style={user.isViewer ? { opacity: 0.5 } : undefined}
                          />
                        )}
                      </Space>
                    </Measurement>
                  </>
                )}
                {setPoint && (
                  <Measurement>
                    {edit ? (
                      <div style={{ display: "inline-block" }} ref={editRef}>
                        <Form
                          form={form}
                          layout="inline"
                          onFinish={handleFinish}
                          initialValues={{
                            value: convertValue(
                              valueMap[v.id].originalUnit,
                              valueMap[v.id].unit,
                              setPointStatus?.value,
                            ),
                          }}
                        >
                          <Form.Item<{ value: string }> label="" name="value">
                            {v.stateDescription ? (
                              <Select
                                disabled={!setPointStatus || updating}
                                autoFocus
                                size="small"
                                style={{ width: "100px" }}
                                options={Object.entries(v.stateDescription).map(
                                  (description) => ({
                                    value: Number(description[0]),
                                    label: description[1],
                                  }),
                                )}
                                onChange={() => form.submit()}
                              />
                            ) : (
                              <InputNumber
                                min={convertValue(
                                  valueMap[v.id].originalUnit,
                                  valueMap[v.id].unit,
                                  valueMap[v.id].minValue,
                                )}
                                max={convertValue(
                                  valueMap[v.id].originalUnit,
                                  valueMap[v.id].unit,
                                  valueMap[v.id].maxValue,
                                )}
                                disabled={!setPointStatus || updating}
                                autoFocus
                                size="small"
                                style={{ width: "100px" }}
                                decimalSeparator=","
                              />
                            )}
                          </Form.Item>
                          <Space.Compact style={{ alignItems: "center" }}>
                            {!setPointStatus || updating ? (
                              <Spin indicator={<LoadingOutlined />} />
                            ) : (
                              <Button
                                style={{ color: PRIMARY }}
                                type="link"
                                size="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  form.submit();
                                }}
                              >
                                <CheckOutlined />
                              </Button>
                            )}
                            <Button
                              style={{ color: ERROR }}
                              type="link"
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                setEdit(false);
                              }}
                            >
                              <CloseOutlined />
                            </Button>
                            <Button
                              disabled={updating}
                              type="link"
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                openSetPointSettings(setPointStatus);
                                handleBlurEdit();
                              }}
                            >
                              <SettingOutlined />
                            </Button>
                          </Space.Compact>
                        </Form>
                      </div>
                    ) : (
                      <>
                        {setPointStatus && !updating ? (
                          getSetPointValue(
                            setPointStatus.value,
                            v.originalUnit,
                            v.unit,
                            v.stateDescription,
                          )
                        ) : (
                          <Spin indicator={<LoadingOutlined />} />
                        )}
                        <EditOutlined
                          style={user.isViewer ? { opacity: 0.5 } : undefined}
                        />
                      </>
                    )}
                  </Measurement>
                )}
                {!setPoint &&
                  !valueMap[v.id] &&
                  showMissingTimeseriesOrSpinner(v.id, v.roomId)}
              </Status>
            </div>
          </ShowTooltip>
        ))}
    </StatusContainer>
  );
};
